import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadingStrategy, Route } from '@angular/router';
import { delay, Observable, of, switchMap } from 'rxjs';
import { AuthGuard } from './guard/auth.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SiteHealthComponent } from './site-health/site-health.component';
import { FranchiseeComponent } from './pages/cms/franchisee/franchisee.component';
import { RefundPolicyComponent } from './pages/cms/refund-policy/refund-policy.component';
import { JuniorhackerComponent } from './pages/cms/juniorhacker/juniorhacker.component';
import { SchoolTieUpProgramComponent } from './pages/cms/school-tie-up-program/school-tie-up-program.component';
import { TabletLearningProgramComponent } from './pages/cms/tablet-learning-program/tablet-learning-program.component';
import { IcatComponent } from './pages/cms/icat/icat.component';
import { CareersformComponent } from './pages/cms/careersform/careersform.component';
import { ContactUsformComponent } from './pages/cms/contact-usform/contact-usform.component';
import { FaqsComponent } from './pages/cms/faqs/faqs.component';
import { ResultsPageComponent } from './Components/results-page/results-page.component';
import { UpcomingExaminationsComponent } from './pages/upcoming-examinations/upcoming-examinations.component';

const routes: Routes = [
  // { path: '', component: AppComponent },
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'health.html', component: SiteHealthComponent },
  // { path: 'askiitians', redirectTo: '', pathMatch: 'full' },
  { path: 'one-to-one-live-classes', loadChildren: () => import('./pages/direct-to-home/direct-to-home.module').then(m => m.DirectToHomeModule), data: { preload: true } },
  { path: 'package', loadChildren: () => import('./pages/cources/cources.module').then(m => m.CourcesModule), data: { preload: true } },
  { path: 'package/:gid', loadChildren: () => import('./pages/cources/cources.module').then(m => m.CourcesModule), data: { preload: true } },
  { path: 'package/:tagid/:gid', loadChildren: () => import('./pages/cources/cources.module').then(m => m.CourcesModule), data: { preload: true } },
  { path: 'resource-page-component', loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule), data: { preload: true } },
  { path: 'resources-page-component', loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule), data: { preload: true } },
  { path: 'my-resources-component/:gid/:sid/:fid', loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule), data: { preload: true } },
  { path: 'resource-details-component/:id/:gid/:sid/:fid', loadChildren: () => import('./pages/resource-details/resource-details.module').then(m => m.ResourceDetailsModule), data: { preload: true } },
  { path: 'forums', loadChildren: () => import(/* webpackChunkName: "batch-discussion" */ './pages/batch-discussion-qa-form/batch-discussion-qa-form.module').then(m => m.BatchDiscussionQaFormModule), data: { preload: true } },
  { path: 'payment-credit-debit-component/:pid', loadChildren: () => import('./pages/payment-credit-debit/payment-credit-debit.module').then(m => m.PaymentCreditDebitModule), data: { preload: true } },
  { path: 'payment-credit-debit-component/:pid/:oid', loadChildren: () => import('./pages/payment-credit-debit/payment-credit-debit.module').then(m => m.PaymentCreditDebitModule), data: { preload: true } },
  { path: 'user/profile', loadChildren: () => import('./pages/private/forum-profile/forum-profile.module').then(m => m.ForumProfileModule), data: { preload: true } },
  { path: 'user', loadChildren: () => import('./pages/private/private.module').then(m => m.PrivateModule), canActivate: [AuthGuard], data: { preload: true } },
  { path: 'genius-kidz/subscriptions', loadChildren: () => import('./pages/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule), data: { preload: true } },
  { path: 'genius-kidz/thank-you', loadChildren: () => import('./pages/thank-you-page/thank-you-page.module').then(m => m.ThankYouPageModule), data: { preload: true } },
  // removed comment for AI{ path: 'subscriptions', loadChildren: () => import('./pages/ai-subscriptions/ai-subscriptions.module').then(m => m.AISubscriptionsModule), data: { preload: true } },
  // { path: 'thank-you', loadChildren: () => import('./pages/ai-thank-you/ai-thank-you.module').then(m => m.AIThankYouModule), data: { preload: true } },
  { path: 'franchisee', redirectTo: 'franchisee/', pathMatch: 'full' },
  { path: 'franchisee/', loadChildren: () => import('./pages/cms/franchisee/franchisee.module').then(m => m.FranchiseeModule), data: { preload: true } },
  { path: 'refund-policy', redirectTo: 'refund-policy/', pathMatch: 'full' },
  { path: 'refund-policy/', component: RefundPolicyComponent }, //note it
  { path: 'juniorhacker', redirectTo: 'juniorhacker/', pathMatch: 'full' },
  { path: 'juniorhacker/', loadChildren: () => import('./pages/cms/juniorhacker/juniorhacker.module').then(m => m.JuniorhackerModule), data: { preload: true } },
  { path: 'school-tie-up-program', redirectTo: 'school-tie-up-program/', pathMatch: 'full' },
  { path: 'school-tie-up-program/', loadChildren: () => import('./pages/cms/school-tie-up-program/school-tie-up-program.module').then(m => m.SchoolTieUpProgramModule), data: { preload: true } },
  { path: 'tablet-learning-program', redirectTo: 'tablet-learning-program/', pathMatch: 'full' },
  { path: 'tablet-learning-program/', loadChildren: () => import('./pages/cms/tablet-learning-program/tablet-learning-program.module').then(m => m.TabletLearningProgramModule), data: { preload: true } },
  { path: 'icat', redirectTo: 'icat/', pathMatch: 'full' },
  { path: 'icat/', loadChildren: () => import('./pages/cms/icat/icat.module').then(m => m.IcatModule), data: { preload: true } },
  { path: 'faqs.aspx', loadChildren: () => import('./pages/cms/faqs/faqs.module').then(m => m.FaqsModule), data: { preload: true } },
  { path: 'careers.aspx', loadChildren: () => import('./pages/cms/careersform/careersform.module').then(m => m.CareersformModule), data: { preload: true } },
  { path: 'contact-us.aspx', loadChildren: () => import('./pages/cms/contact-usform/contact-usform.module').then(m => m.ContactUsformModule), data: { preload: true } },
  { path: 'ResultsPage.aspx', loadChildren: () => import('./Components/results-page/results-page.module').then(m => m.ResultsPageModule), data: { preload: true } },
  { path: 'upcoming-examinations', loadChildren: () => import('./pages/upcoming-examinations/upcoming-examinations.module').then(m => m.UpcomingExaminationsModule), data: { preload: true } },
  { path: 'home',redirectTo: '', pathMatch: 'full'},
  { path: '',loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), data: { preload: true }, pathMatch: 'full' },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', loadChildren: () => import('./pages/cms/cms.module').then(m => m.CMSModule), data: { preload: true } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return of(true).pipe(
      delay(1000),
      switchMap(() => load())
    );
  }
}
