import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GradeModel } from 'src/app/Models/GradeModel';
import { Registration } from 'src/app/Models/RegistrationModel';
import { CacheService } from 'src/app/services/cache.service';
import { CourceService } from 'src/app/services/cource.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { NotificationType } from 'src/app/enum/NotificationType';
import { NumberValidator } from 'src/app/country-picker/number-validator';
import { TrialService } from 'src/app/services/trial.service';
import { TrialUserComponent } from '../trial-user/trial-user.component';
import { ClientIPAddressService } from 'src/app/services/client-ipaddress.service';
import { CMSNavigationService } from 'src/app/services/cms-navigation.service';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { SourceIdEnum } from 'src/app/Models/sourceIdEnumModel';
import { CountryCodeModel } from 'src/app/Models/CountryCode.Model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  @Output() isUserLogged: EventEmitter<any> = new EventEmitter();
  @Output() userid: EventEmitter<any> = new EventEmitter();
  @Output() isRegistered: EventEmitter<any> = new EventEmitter();

  @Input() sourceId: number = 1;
  @Input() isCMS!: string;

  form!: FormGroup;
  isLoading: boolean = false;
  getGrade: Array<GradeModel> = [];
  userDetails: any;
  registration!: Registration;
  userID!: number;
  phoneNumber: string = '';
  @Input() isShowCrossbtn: boolean = false;
  @Input() password!: string;
  @Input() isGoogleSignIn!: boolean;
  @Input() googleSignInUserId!: string;
  UserId: number | undefined;
  clientIPAdress!: string;
  country!: string;
  city!: string;
  state!: string;
  otp: string | undefined;
  verificationId: number | undefined;
  countryCodes: any[] = CountryCodeModel;
  
  constructor(
    private courceService: CourceService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private cacheService: CacheService,
    private userService: UserService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private trialService: TrialService,
    private clientIPAdressService: ClientIPAddressService,
    private cmsService: CMSNavigationService,
    public activeModal: NgbActiveModal,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.form = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, NumberValidator]],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
      gradeId: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.courceService.getAllGrades().subscribe((res) => {
      this.getGrade = res;
    });
    this.form.patchValue({
      email: this.storageService.getItem(StorageKey.EMAIL, StorageType.LOCAL),
    });
    this.phoneNumber = this.storageService.getItem(StorageKey.MOBILE_NO, StorageType.LOCAL);
    if (this.phoneNumber == null) {
      this.form.patchValue({
        phoneNumber: '',
      });
    } else {
      this.form.patchValue({
        phoneNumber: this.phoneNumber,
      });
    }
    this.clientIPAdressService.getClientIp().subscribe((data: any) => {
      this.clientIPAdress = data.response.query;
      //this.country = data.response.country;
      this.state = data.response.state;
    });
  }
  get f() {
    return this.form?.controls;
  }
  validateUser() {
    if (this.isGoogleSignIn == true) {
      this.sourceId == SourceIdEnum.GoogleSignIn;
    }
    if (!this.form.invalid) {
      this.isLoading = true;
      console.log('going in validate user');
      if (this.phoneNumber == null) {
        this.phoneNumber = this.form.value.phoneNumber;
      } else {
        this.isLoading = false;
        this.phoneNumber = this.phoneNumber;
      }
      this.userService.checkUser(this.phoneNumber).subscribe({
        next: (isExistUser) => {
          if (isExistUser.email === this.form.value.email) {
            this.userID = isExistUser.id;
            this.sourceId = isExistUser.sourcenameid;
            this.isLoading = true;
            this.password = isExistUser.password;
            this.updateUserDetails();
            this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
            this.cacheService.setIsUserLoggedIn(true);
            this.getUserDetailsById(this.userID);
            this.isRegistered.emit(false);
            this.isUserLogged.emit(true);
            this.closeModal();
            this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged in!`);
            this.getUserStatus();
            this.cmsService.hideNRIBookNowButton();
          } else {
            this.isLoading = false;
            this.notificationService.notifyMessage(
              NotificationType.Warning,
              `You're already Register with this number!`
            );
          }
        },
        error: () => {
          if (this.isGoogleSignIn == true) {
            this.sourceId = SourceIdEnum.GoogleSignIn;
            this.userID = Number(this.googleSignInUserId);
            this.updateUserDetails();
          }
          else {
            this.isLoading = false;
            this.registerUser();
          }
        },
      });
    } else {
      this.form.markAllAsTouched();
      return;
    }
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddTHH:mm:ss.SSS'Z'");
  }

  registerUser() {
    this.isLoading = true;
    const countrydata = this.countryCodes.find(x =>x.code ==this.form.value.phoneNumber.split('-')[0]);
    this.country = countrydata.country;
    console.log('going in registeruser');
    this.activeModal.close();
    const modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRefOTPVerification.componentInstance.phoneNo = this.form.value.phoneNumber;
    modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
      modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
        this.otp = otp;
        modalRefOTPVerification.componentInstance.VerificationId.subscribe((VerificationId: number) => {
          this.verificationId = VerificationId;
          if (isOtpVerfied == true) {
            this.registration = {
              id: 0,
              name: this.form.value.name,
              email: this.form.value.email,
              isActive: true,
              password: this.password,
              mobile: this.form.value.phoneNumber,
              createdDateTime: this.transformDate(new Date())?.toString(),
              modifiedBy: '',
              userTypeId: 1,
              lastModified: this.transformDate(new Date())?.toString(),
              registrationUrl: String(window.location.pathname),
              sourceNameId: this.sourceId,
              ipCountry: this.country,
              ipAddress: this.clientIPAdress,
              city: '',
              VerificationId: this.verificationId,
              otp: this.otp,
              userDetails: {
                id: 0,
                grade: String(this.form.value.gradeId),
                userId: 0,
                schoolName: '',
                modifiedDate: this.transformDate(new Date())?.toString(),
              },
              mobileVerification: {
                verificationId: 0,
              },
            };
            if (this.registration.mobile != null) {
              this.userService.create(this.registration).subscribe((data) => {
                if (data.result.value.id != null) {
                  this.userID = data.result.value.id;
                  this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
                  this.cacheService.setIsUserLoggedIn(true);
                  this.getUserDetailsById(this.userID);
                  this.isRegistered.emit(false);
                  this.closeModal();
                  this.isLoading = false;
                  this.notificationService.notifyMessage(NotificationType.Success, `You're Register Successfully!`);
                  modalRefOTPVerification.close();
                  this.cmsService.hideNRIBookNowButton();
                } else {
                  this.isLoading = false;
                  this.notificationService.notifyMessage(NotificationType.Warning, `Sorry ! Unable to Register you!`);
                }
              });
            }
          }
        });
      });
    });
  }

  getUserDetailsById(id: number) {
    this.userService.getUserFromID(id).subscribe((res) => {
      if (res) {
        this.userDetails = res;
        this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
        this.storageService.setItem(StorageKey.USER, JSON.stringify(res), StorageType.LOCAL);
        this.cacheService.onUserDetailsChange(res);
      }
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  updateUserDetails() {
    const countrydata = this.countryCodes.find(x =>x.code ==this.form.value.phoneNumber.split('-')[0]);
    this.country = countrydata.country;
    var mobileNumber: any = this.phoneNumber;
    if (
      !mobileNumber ||
      typeof mobileNumber === 'function' // Check if it's a function
    ) {
      mobileNumber = this.phoneNumber; // Set the default phone number value
    }
    this.registration = {
      id: this.userID,
      name: this.form.value.name,
      email: this.form.value.email,
      isActive: true,
      password: this.password,
      mobile: mobileNumber,
      createdDateTime: this.transformDate(new Date())?.toString(),
      modifiedBy: '',
      userTypeId: 1,
      lastModified: this.transformDate(new Date())?.toString(),
      registrationUrl: String(window.location.pathname),
      sourceNameId: this.sourceId,
      ipCountry: this.country,
      ipAddress: this.clientIPAdress,
      city: '',
      VerificationId: this.verificationId,
      otp: this.otp,
      userDetails: {
        id: 0,
        grade: String(this.form.value.gradeId),
        userId: this.userID,
        schoolName: '',
        modifiedDate: this.transformDate(new Date())?.toString(),
        country: this.country,
        city: this.city,
      },
      mobileVerification: {
        verificationId: 0,
      },
    };
    this.userService.updateUserDetail(this.userID, this.registration).subscribe((data) => {
      this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
      this.cacheService.setIsUserLoggedIn(true);
      this.getUserDetailsById(this.userID);
      this.isRegistered.emit(true);
      this.closeModal();
      this.getUserStatus();
      this.cmsService.hideNRIBookNowButton();
    });
  }
  getUserStatus(): any {
    if (this.UserId) {
      this.trialService.GetOrder(this.UserId).subscribe((data) => {
        if (data.length > 0) {
        } else {
          setTimeout(() => {
            const modalRef = this.modalService.open(TrialUserComponent, {
              centered: true,
              size: 'xl',
              backdrop: 'static',
            });
            modalRef.componentInstance.UserId = this.UserId;
            modalRef.componentInstance.GradeId = this.form.value.gradeId;
          }, 3000);
        }
      });
    }
  }
}
