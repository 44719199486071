import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CmsJsService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  jsEnable() {
    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(function () {

        // const me = this;
        const section = document.querySelector('.live-img-sec') as HTMLElement;
        const section2 = document.querySelector('#nriAvailNowDiv') as HTMLElement;
        const section3 = document.querySelector('.join-now-section') as HTMLElement;
        const element = document.querySelector('#textIITNRI') as HTMLElement;
        const element2 = document.querySelector('#textNEETNRI') as HTMLElement;
        // const requestACallBack = document.querySelector('#requestACallBack') as HTMLElement;
        const requestACallBacks = document.querySelectorAll<HTMLElement>('#requestACallBack');
        if (section && section2 && section3 && (element || element2)) {
          let display = 'block';
          if (element) { element.classList.add('col-md-8'); }
          if (element2) { element2.classList.add('col-md-8'); }
          if (localStorage.getItem('isLoggedIn') == "true") {
            display = 'none';
            if (element) { element.classList.remove('col-md-8'); }
            if (element2) { element2.classList.remove('col-md-8'); }
          }
          section.style.display = display;
          section2.style.display = display;
          section3.style.display = display;
        }
        else if(requestACallBacks)
        {
          requestACallBacks.forEach((element) => {
            element.style.display = 'none';
        });
        }

        $(window).scroll(function () {
          var testimonialContainer = $('.testimonial-section').find('.testimonial-container');
          var slideInLeftDiv = testimonialContainer.find('.col-md-4.wow.slideInLeft');
          var slideInUpDiv = testimonialContainer.find('.col-md-4.wow.slideInUp');
          var testimonialVideoDiv = slideInLeftDiv.find('.testimonial-video');
          var testimonialVideoDiv2 = slideInUpDiv.find('.testimonial-video');
          if (testimonialVideoDiv.find('iframe').length === 0) {
            var iframe = $('<iframe></iframe>', {
              'allow': 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
              'allowfullscreen': '',
              'frameborder': '0',
              'height': '230',
              'src': 'https://www.youtube.com/embed/_C0nytPDBy4',
              'width': ''
            });
            testimonialVideoDiv.append(iframe);
          } else if (testimonialVideoDiv2.find('iframe').length === 0) {
            var iframe = $('<iframe></iframe>', {
              'allow': 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
              'allowfullscreen': '',
              'frameborder': '0',
              'height': '230',
              'src': 'https://www.youtube.com/embed/70cqcRD5lD8',
              'width': ''
            });
            testimonialVideoDiv2.append(iframe);
          }

          if ($('.add-owl-carousel').length > 0) {
            $('.add-owl-carousel').owlCarousel({
              items: 1,
              loop: true,
              margin: 10,
            });
          }

        });

        $("#id-read-more").click(function () {

          $("#id-show-more-content").show();
          $("#id-read-more").hide();
          $("#id-read-less").show();
        });
        $("#id-read-less").click(function () {

          $("#id-show-more-content").hide();
          $("#id-read-less").hide();
          $("#id-read-more").show();
        });

        $("#ied-read-more").click(function () {
          $("#ied-show-more-content").show();
          $("#ied-read-more").hide();
          $("#ied-read-less").show();

        })
        $("#ied-read-less").click(function () {

          $("#ied-show-more-content").hide();
          $("#ied-read-less").hide();
          $("#ied-read-more").show();
        });
        $("#iac-read-more").click(function () {
          $("#iac-show-more-content").show();
          $("#iac-read-more").hide();
          $("#iac-read-less").show();

        })
        $("#iac-read-less").click(function () {

          $("#iac-show-more-content").hide();
          $("#iac-read-less").hide();
          $("#iac-read-more").show();
        });
        $("#ie-read-more").click(function () {
          $("#ie-show-more-content").show();
          $("#ie-read-more").hide();
          $("#ie-read-less").show();

        })
        $("#ie-read-less").click(function () {

          $("#ie-show-more-content").hide();
          $("#ie-read-less").hide();
          $("#ie-read-more").show();
        });
        $("#ir-read-more").click(function () {
          $("#ir-show-more-content").show();
          $("#ir-read-more").hide();
          $("#ir-read-less").show();

        })
        $("#ir-read-less").click(function () {

          $("#ir-show-more-content").hide();
          $("#ir-read-less").hide();
          $("#ir-read-more").show();
        });

        $("#id-read-more").click(function () {

          $("#id-show-more-content").show();
          $("#id-read-more").hide();
          $("#id-read-less").show();
        });


        $("#id-read-less").click(function () {

          $("#id-show-more-content").hide();
          $("#id-read-less").hide();
          $("#id-read-more").show();
        });

        $("#ied-read-more").click(function () {
          $("#ied-show-more-content").show();
          $("#ied-read-more").hide();
          $("#ied-read-less").show();

        })
        $("#ied-read-less").click(function () {

          $("#ied-show-more-content").hide();
          $("#ied-read-less").hide();
          $("#ied-read-more").show();
        });
        $("#iac-read-more").click(function () {
          $("#iac-show-more-content").show();
          $("#iac-read-more").hide();
          $("#iac-read-less").show();

        })
        $("#iac-read-less").click(function () {

          $("#iac-show-more-content").hide();
          $("#iac-read-less").hide();
          $("#iac-read-more").show();
        });
        $("#ie-read-more").click(function () {
          $("#ie-show-more-content").show();
          $("#ie-read-more").hide();
          $("#ie-read-less").show();

        })
        $("#ie-read-less").click(function () {

          $("#ie-show-more-content").hide();
          $("#ie-read-less").hide();
          $("#ie-read-more").show();
        });
        $("#ir-read-more").click(function () {
          $("#ir-show-more-content").show();
          $("#ir-read-more").hide();
          $("#ir-read-less").show();

        })
        $("#ir-read-less").click(function () {

          $("#ir-show-more-content").hide();
          $("#ir-read-less").hide();
          $("#ir-read-more").show();
        });

        $("#iep-read-more").click(function () {
          $("#iep-show-more-content").show();
          $("#iep-read-more").hide();
          $("#iep-read-less").show();

        })
        $("#iep-read-less").click(function () {
          $("#iep-show-more-content").hide();
          $("#iep-read-less").hide();
          $("#iep-read-more").show();
        });
        // For NEET PAGE
        $("#nd-read-more").click(function () {
          $("#nd-show-more-content").show();
          $("#nd-read-more").hide();
          $("#nd-read-less").show();

        })
        $("#nd-read-less").click(function () {
          $("#nd-show-more-content").hide();
          $("#nd-read-less").hide();
          $("#nd-read-more").show();
        });
        $("#ned-read-more").click(function () {
          $("#ned-show-more-content").show();
          $("#ned-read-more").hide();
          $("#ned-read-less").show();

        })
        $("#ned-read-less").click(function () {
          $("#ned-show-more-content").hide();
          $("#ned-read-less").hide();
          $("#ned-read-more").show();
        });
        $("#nac-read-more").click(function () {
          $("#nac-show-more-content").show();
          $("#nac-read-more").hide();
          $("#nac-read-less").show();

        })
        $("#nac-read-less").click(function () {
          $("#nac-show-more-content").hide();
          $("#nac-read-less").hide();
          $("#nac-read-more").show();
        });
        $("#ne-read-more").click(function () {
          $("#ne-show-more-content").show();
          $("#ne-read-more").hide();
          $("#ne-read-less").show();

        })
        $("#ne-read-less").click(function () {
          $("#ne-show-more-content").hide();
          $("#ne-read-less").hide();
          $("#ne-read-more").show();
        });
        $("#nr-read-more").click(function () {
          $("#nr-show-more-content").show();
          $("#nr-read-more").hide();
          $("#nr-read-less").show();

        })
        $("#nr-read-less").click(function () {
          $("#nr-show-more-content").hide();
          $("#nr-read-less").hide();
          $("#nr-read-more").show();
        });
        $("#nep-read-more").click(function () {
          $("#nep-show-more-content").show();
          $("#nep-read-more").hide();
          $("#nep-read-less").show();

        })
        $("#nep-read-less").click(function () {
          $("#nep-show-more-content").hide();
          $("#nep-read-less").hide();
          $("#nep-read-more").show();
        });
      });



    }
  }

  jsEnableForNRISlider()
  {
    if (isPlatformBrowser(this.platformId)) {
      // $("div.tabss").css({ "display": "none" });
      //       $("#tabs1").css({ "display": "block" });
      //       $(".tablink").click(function () {
      //           $("div.tabss").css({ "display": "none" });
      //           var val = $(this)[0].attributes["name"].value;
      //           $(val).css({ "display": "block" });
      //           $('.tablink').removeClass('active');
      //           $(this).addClass('active');
      //       });
    $(document).ready(function () {
      if (typeof $.fn.slick === 'function') {
      $('#slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2000,
      });
    }
    });
    $(document).ready(function () {
      const $slider = $('.grey-box'); // Main slider container
      const $videoBoxes = $slider.find('.box-dflt'); // Video boxes inside the slider
      let currentIndex: number = 0; // Index of the current video
      let autoSlideInterval: ReturnType<typeof setInterval>;// Declare auto-slide interval with a numeric type
  
      // Update which video is visible
      function updateSlider() {
          $videoBoxes.removeClass('on').addClass('off'); // Hide all videos
          $videoBoxes.eq(currentIndex).removeClass('off').addClass('on'); // Show the current video
      }
  
      // Move to the next video
      function showNextVideo() {
          currentIndex = (currentIndex + 1) % $videoBoxes.length; // Loop to the first after the last
          updateSlider();
      }
  
      // Move to the previous video
      function showPreviousVideo() {
          currentIndex = (currentIndex - 1 + $videoBoxes.length) % $videoBoxes.length; // Loop to the last before the first
          updateSlider();
      }
  
      // Start the auto slider
      function startAutoSlider() {
          autoSlideInterval = setInterval(showNextVideo, 13000); // Auto switch every 3 seconds
      }
  
      // Stop the auto slider
      function stopAutoSlider() {
          clearInterval(autoSlideInterval);
      }
  
      // Attach manual navigation handlers
      $('#dvNextVideo').on('click', function () {
          stopAutoSlider(); // Stop auto-slide
          showNextVideo(); // Show next video
          startAutoSlider(); // Restart auto-slide
      });
  
      $('#dvPrevVideo').on('click', function () {
          stopAutoSlider(); // Stop auto-slide
          showPreviousVideo(); // Show previous video
          startAutoSlider(); // Restart auto-slide
      });
  
      // Initialize the slider
      updateSlider(); // Set the initial state
      startAutoSlider(); // Start auto-slide
  });
  $(document).ready(function () {
    const $testimonials = $('.testimonials'); // Container for testimonials
    const $testimonialTexts = $testimonials.find('.more'); // All testimonials
    let currentTestimonialIndex: number = 0; // Index of the current testimonial
    let autoTestimonialInterval: ReturnType<typeof setInterval>; // Auto slider interval

    // Function to update the visibility of testimonials
    function updateTestimonials() {
        $testimonialTexts.removeClass('on').addClass('off'); // Hide all testimonials
        $testimonialTexts.eq(currentTestimonialIndex).removeClass('off').addClass('on'); // Show the current testimonial
    }

    // Show next testimonial
    function showNextTestimonial() {
        currentTestimonialIndex = (currentTestimonialIndex + 1) % $testimonialTexts.length; // Loop to first after the last
        updateTestimonials();
    }

    // Show previous testimonial
    function showPreviousTestimonial() {
        currentTestimonialIndex = (currentTestimonialIndex - 1 + $testimonialTexts.length) % $testimonialTexts.length; // Loop to last before the first
        updateTestimonials();
    }

    // Start the auto slider
    function startAutoSlider() {
        autoTestimonialInterval = setInterval(showNextTestimonial, 15000); // Auto switch every 5 seconds
    }

    // Stop the auto slider
    function stopAutoSlider() {
        clearInterval(autoTestimonialInterval);
    }

    // Manual navigation buttons
    $('#dvNextTest').on('click', function () {
        stopAutoSlider(); // Stop auto slide
        showNextTestimonial(); // Show next testimonial
        startAutoSlider(); // Restart auto slide
    });

    $('#dvPrevTest').on('click', function () {
        stopAutoSlider(); // Stop auto slide
        showPreviousTestimonial(); // Show previous testimonial
        startAutoSlider(); // Restart auto slide
    });

    // Initialize the slider
    updateTestimonials(); // Set the initial state
    startAutoSlider(); // Start the auto slider
});
    }

  }
}