import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherSectionComponent } from '../Components/teacher-section/teacher-section.component';
import { TopResultsComponent } from '../Components/top-results/top-results.component';
import { FaqSectionComponent } from '../Components/faq-section/faq-section.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NoSanitizePipe } from '../pipes/no-sanitize.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RightBlockComponent } from '../Components/right-block/right-block.component';
import { FormsModule } from '@angular/forms';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { HtmlToSimpleTextPipe } from '../pipes/html-to-simple-text.pipe';
import { ResourceVideoViewComponent } from '../popupModel/resource-video-view/resource-video-view.component';
import { CareersformModule } from '../pages/cms/careersform/careersform.module';
import { CareersformComponent } from '../pages/cms/careersform/careersform.component';
@NgModule({
  declarations: [
    TopResultsComponent,
    TeacherSectionComponent,
    FaqSectionComponent,
    RightBlockComponent,
    ResourceVideoViewComponent,
    NoSanitizePipe,
    OrderByPipe,
    HtmlToSimpleTextPipe,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    NgbModule,
    FormsModule
  ],
  exports: [
    TopResultsComponent,
    TeacherSectionComponent,
    FaqSectionComponent,
    NoSanitizePipe,
    RightBlockComponent,
    OrderByPipe,
    
  ]
})
export class SharedModule { }
