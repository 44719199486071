import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FreeTrialNotifyComponent } from '../free-trial-notify/free-trial-notify.component';
import { LoginWithEmailComponent } from '../login-with-email/login-with-email.component';
import { LoginComponent } from '../login/login.component';
import { TrialUserComponent } from '../trial-user/trial-user.component';

@Component({
  selector: 'app-resource-video-view',
  templateUrl: './resource-video-view.component.html',
  styleUrls: ['./resource-video-view.component.css']
})
export class ResourceVideoViewComponent implements OnInit {
  @Input() ImageURL!: any;
  @Input() Link!: any;
  @Input() isPackageVideo: boolean | undefined;
  @Input() AccessStatus!: any;
  @Input() UserId!: any;
  @Input() UnPaidContent!: boolean;
  isBrowser: boolean = false;

  VideoSrc!: string;
  VideoLink!: string;
  UserID!: number;
  id: any;
  isTrialPending = false;
  isTrialExpired = false;
  isPackageExpired = false;
  freeTrialStatus: any;
  videoUrl: string = '';

  constructor(private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.openVideoIsAccess(this.Link);
  }

  openVideoIsAccess(link: any) {
    if (this.isPackageVideo == true) {
      this.videoUrl = link;
    }
    else {
      if (this.UserId == null || this.UserId == undefined || localStorage.getItem('isLoggedIn') == "false") {
        this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
      }
      else {
        if (this.UnPaidContent) {
          this.videoUrl = link;
        }
        else if (this.AccessStatus == "TrialPending") {
          this.isTrialPending = true;
          const modalRef = this.modalService.open(TrialUserComponent, { centered: true, size: 'xl', backdrop: 'static' },);
          modalRef.componentInstance.UserId = this.UserId;
        }
        else if (this.AccessStatus == "TrialExpired") {
          this.isTrialExpired = true;
          const modalRef = this.modalService.open(FreeTrialNotifyComponent, { size: 'xl', centered: true, backdrop: 'static' });
          modalRef.componentInstance.FreeTrialStatus = this.freeTrialStatus;
        }
        else if (this.AccessStatus == "PackageExpired") {
          this.isPackageExpired = true;
          const modalRef = this.modalService.open(FreeTrialNotifyComponent, { size: 'xl', centered: true, backdrop: 'static' });
          modalRef.componentInstance.FreeTrialStatus = this.freeTrialStatus;
        }
        else {
          this.videoUrl = link;
        }
      }
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
