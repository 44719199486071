<div  [ngClass]="{'toggle-desktop position-fixed': !childData, 'mobile-content': childData ,'tushar tushar1' : expandMenu}">
    <div [ngClass]="{'desktop-content': !childData, 'mobile-content': childData}">
        <ul>
            <li class="active">
                <a routerLink="/user/dashboard-component" (click)="expandMenu = !expandMenu;sendDataToParent()" routerLinkActive="active" ariaCurrentWhenActive="page"
                    class="d-flex">
                    <img src="/assets/images/sidepanel/home.png" width="20" height="20"> Home
                </a>
            </li>
            <li>
                <a routerLink="/subscriptions" (click)="expandMenu = !expandMenu;sendDataToParent()" title="My Class" class="d-flex" href="javascript:void(0);">
                    <img src="/assets/images/sidepanel/class.png" width="20" height="20"> Subscription
                </a>
            </li>
            <li>
                <a (click)="sendDataToParent(); getClassLink();" title="My Class" class="d-flex" href="javascript:void(0);">
                    <img src="/assets/images/sidepanel/class.png" width="20" height="20"> My Class
                </a>
            </li>
            <li>
                <a (click)="expandMenu = !expandMenu;sendDataToParent() ;getTestSeriesLimk();" title="Test Series" target="_blank" class="d-flex">
                    <img src="/assets/images/sidepanel/test.png" width="20" height="20"> Test Series
                </a>
            </li>

            <li *ngIf="isPackage">
                <a title="My Resources" routerLink="/resources-page-component" (click)="expandMenu = !expandMenu;sendDataToParent()" class="d-flex">
                    <img src="/assets/images/sidepanel/resources.png" width="20" height="20"> My Resources
                </a>
            </li>
           
            <li>
                <a  title="Question"  routerLink="/forums/" routerLinkActive="active" (click)="expandMenu = !expandMenu;sendDataToParent()" ariaCurrentWhenActive="page" class="d-flex">
                    <img src="/assets/images/sidepanel/question.png" width="20" height="20"> Question
                </a>
            </li>
            <li>
                <a title="User Profile"   routerLink="/user/profile-component" (click)="expandMenu = !expandMenu;sendDataToParent()" routerLinkActive="active" ariaCurrentWhenActive="page"
                    class="d-flex">
                    <img src="/assets/images/sidepanel/account.png" width="20" height="20"> User Profile
                </a>
            </li>
        </ul>
    </div>
</div>