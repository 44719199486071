import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from "rxjs";
import { NoificationMessageComponent } from '../popupModel/noification-message/noification-message.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notification = new Subject<Notification>();

  constructor(private modalService: NgbModal) { }

  getObservable(): Observable<Notification> {
    return this._notification.asObservable();
  }

  public notifyMessage(NotificationType: string, Message: string) {
    const notificationModelRef = this.modalService.open(NoificationMessageComponent, { size: 'sm', windowClass: 'notificationMessage', backdrop: 'static' });
    notificationModelRef.componentInstance.notificationType = NotificationType;
    notificationModelRef.componentInstance.Message = Message;
    notificationModelRef.componentInstance.isShow = true;

  }
}

