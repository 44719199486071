import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/enum/NotificationType';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { RegistrationComponent } from '../registration/registration.component';
import { SourceIdEnum } from 'src/app/Models/sourceIdEnumModel';
import { GoogleResponseModel, GoogleResponseRequestModel } from 'src/app/Models/GoogleResponseModel';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { DatePipe } from '@angular/common';
import { Registration } from 'src/app/Models/RegistrationModel';
import { TrialService } from 'src/app/services/trial.service';
import { TrialUserComponent } from '../trial-user/trial-user.component';
import { environment } from 'src/environments/environment';
import { ClientIPAddressService } from 'src/app/services/client-ipaddress.service';
import { NumberValidator } from 'src/app/country-picker/number-validator';
import { CountryCodeModel } from 'src/app/Models/CountryCode.Model';

@Component({
  selector: 'app-login-with-email',
  templateUrl: './login-with-email.component.html',
  styleUrls: ['./login-with-email.component.css'],
})
export class LoginWithEmailComponent implements OnInit {
  @Input() sourceId!: number;
  @Output() isUserLogged: EventEmitter<any> = new EventEmitter();
  @Output() userid: EventEmitter<any> = new EventEmitter();
  @Input() isResendOTP: boolean = false;
  loginWithEmailForm!: FormGroup;
  mobileVerificationForm!: FormGroup;
  userID!: number;
  isLoading: boolean = false;
  isForgotPassword: boolean = false;
  googleClientId: string = environment.GoogleClientId;
  googleResponseRequestModel!: GoogleResponseRequestModel;
  googleResponseModel!: GoogleResponseModel;
  isVerified: boolean = false;
  isSignInwithEmail: boolean = false;
  isUserExist: boolean = false;
  createPassword: boolean = false;
  showPasswordField: boolean = false;
  validatePhoneNumber: Boolean = false;
  userData: any;
  registrationModal!: Registration;
  isValidPassword: boolean = true;
  registration!: Registration;
  grade!: string;
  clientIPAdress!: string;
  country!: string;
  city!: string;
  state!: string;
  check = false;
  isVerifyOtp = false;
  otp: string | undefined;
  verificationId: number | undefined;
  modalRefOTPVerification: any;
  isEmailSmall: boolean = false;
  googleSignInEmail: string = '';
  isGoogleSignIn: boolean = false;
  countryCodes: any[] = CountryCodeModel;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private cachaService: CacheService,
    private datePipe: DatePipe,
    private trialService: TrialService,
    private clientIPAdressService: ClientIPAddressService
  ) {}
  ngOnInit() {
    this.loginWithEmailForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
      Password: ['', Validators.required],
    });
    this.mobileVerificationForm = this.formBuilder.group({
      PhoneNo: ['', [Validators.required, NumberValidator]],
    });
    this.clientIPAdressService.getClientIp().subscribe((data: any) => {
      this.clientIPAdress = data.response.query;
      //this.country = data.response.country;
      this.state = data.response.state; // Extract the state
    });
  }

  get e() {
    return this.loginWithEmailForm.controls;
  }
  get f() {
    return this.mobileVerificationForm.controls;
  }

  forgotPassword() {
    this.isForgotPassword = true;
    this.isSignInwithEmail = true;
  }

  closeModal() {
    this.activeModal.close();
  }

  getUserDetailsById(userid: number) {
    this.userService.getUserFromID(userid).subscribe((res) => {
      this.grade = res.userdetails.grade;
      this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
      this.storageService.setItem(StorageKey.USER, JSON.stringify(res), StorageType.LOCAL);
      this.cachaService.onUserDetailsChange(res);
    });
  }

  getPassword() {
    this.isLoading = true;
    this.userService.checkEmailId(this.loginWithEmailForm.value.Email).subscribe((isExistUser) => {
      if (isExistUser.length > 0) {
        this.userService.forgotPassword(this.loginWithEmailForm.value.Email).subscribe({
          next: (response) => {
            this.activeModal.close();
            if (response == 'Password has been sent to your registered email ID.') {
              this.notificationService.notifyMessage(
                NotificationType.Success,
                `Password has been sent to your registered email ID Please Check your Email`
              );
              this.isLoading = false;
            } else {
              if (response.status === 400) {
                this.notificationService.notifyMessage(
                  NotificationType.Success,
                  `You are not a registered user. Please register as a new user.`
                );
              }
              this.isLoading = false;
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.notificationService.notifyMessage(NotificationType.Success, `Please Provide a valid email address.`);
            }
          },
        });
        this.isLoading = false;
      } else {
        this.notificationService.notifyMessage(
          NotificationType.Success,
          `You are not a registered user. Please register as a new user.`
        );
        this.activeModal.close();
        const sourceId = SourceIdEnum.Register;
        const modalRef = this.modalService.open(RegistrationComponent, { centered: true, backdrop: 'static' });
        modalRef.componentInstance.isShowCrossbtn = true;
        modalRef.componentInstance.sourceId = sourceId;
      }
      this.isLoading = false;
    });
  }

  onGoogleSigninSuccess(data: any) {
    this.isGoogleSignIn = true;
    const sourceId = SourceIdEnum.GoogleSignIn;
    this.googleResponseModel = data;
    this.googleResponseRequestModel = {
      email: data.email,
      name: data.name,
      grade: '',
      mobile: '',
      password: '',
      profilePictureUrl: data.picture,
      registrationUrl: String(window.location.pathname),
      sourceNameId: sourceId,
      ipAddress: this.clientIPAdress,
      isMobile: false,
      ipCountry: this.country,
      city: '',
      googleUniqueId: '',
      accessToken: '',
      // expiresAt: '',
      // expiresIn: '',
      // firstIssuedAt: '',
      // providerId: data.Sc != undefined ? data.Sc.idpId : data.Tc.idpId,
      // scope: data.Sc != undefined ? data.Sc.scope : data.Tc.scope
    };
    this.userService.checkEmailId(data.email).subscribe((isExistUser) => {
      this.googleSignInEmail = data.email;
      this.storageService.setItem(StorageKey.EMAIL, this.googleSignInEmail, StorageType.LOCAL);
      if (isExistUser.length > 0) {
        this.userID = isExistUser[0].Id;
        this.setUserLoggedIn();
      } else {
        this.isGoogleSignIn= true;
        this.validatePhoneNumber = true;
        // this.storageService.setItem(
        //   StorageKey.MOBILE_NO,
        //   this.mobileVerificationForm.value.PhoneNo,
        //   StorageType.LOCAL
        // );
        // this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
        //   centered: true,
        //   backdrop: 'static',
        // });
        // this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
        //   this.modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
        //     this.otp = otp;
        //     this.modalRefOTPVerification.componentInstance.VerificationId.subscribe((VerificationId: number) => {
        //       this.verificationId = VerificationId;
        //       if (isOtpVerfied == true && VerificationId) {
        //         if (this.isUserExist == true && this.userData[0].Email) {
        //           this.getUserDetailsById(this.userID);
        //           this.activeModal.close();
        //           const modalRefRegistration = this.modalService.open(RegistrationComponent, {
        //             centered: true,
        //             backdrop: 'static',
        //           });
        //           modalRefRegistration.componentInstance.isShowCrossbtn = true;
        //           modalRefRegistration.componentInstance.password = this.userData[0].Password;
        //           modalRefRegistration.componentInstance.isGoogleSignIn = true;
        //           modalRefRegistration.componentInstance.googleSignInUserId = this.userID;
        //           modalRefRegistration.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
        //             if (isUserLogged == true) {
        //               this.isUserLogged.emit(isUserLogged);
        //             }
        //           });
        //         } else {
        //           this.registerUser();
        //         }
        //       }
        //     });
        //   });
        // });
      }
      // this.storageService.setItem(StorageKey.EMAIL, this.googleSignInEmail, StorageType.LOCAL);
      // this.userService.saveGoogleResponse(this.googleResponseRequestModel).subscribe((res) => {
      //   if (res.id != null || res.id > 0) {
      //     if (res.isFirstTimeSignIn == false) {
      //       if (res.mobile == '' || res.mobile == undefined) {
      //         this.validatePhoneNumber = true;
      //         this.sourceId=45;
      //       } else {
      //         this.userID = res.id;
      //         this.setUserLoggedIn();
      //       }
      //     } else {
      //       if (res.mobile == '' || res.mobile == undefined) {
      //         this.validatePhoneNumber = true;
      //         this.sourceId=45;
      //       } else {
      //         this.userID = res.id;
      //         this.setUserLoggedIn();
      //       }
      //     }
      //   } else {
      //     this.notificationService.notifyMessage(NotificationType.Warning, `Login Failed`);
      //   }
      // });
    });
  }

  loginwithEmail() {
    this.isSignInwithEmail = true;
    this.isForgotPassword = false;
  }

  keyUpEvent() {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (this.loginWithEmailForm.controls['Email'].invalid) {
      return;
    }
    if (!emailRegex.test(this.loginWithEmailForm.controls['Email'].value)) {
      this.isEmailSmall = true;
    } else {
      this.storageService.setItem(StorageKey.EMAIL, this.loginWithEmailForm.controls['Email'].value, StorageType.LOCAL);
      this.userService.checkEmailId(this.loginWithEmailForm.controls['Email'].value).subscribe((userData) => {
        this.userData = userData;
        if (userData.length > 0) {
          this.isUserExist = true;
          this.createPassword = false;
          this.showPasswordField = true;
          this.validatePhoneNumber = false;
        } else {
          this.isUserExist = false;
          this.createPassword = true;
          this.showPasswordField = true;
        }
      });
    }
  }

  VerifyMobileNumber() {
    this.check = true;
    if (this.isUserExist == true) {
      this.userID = this.userData[0].Id;
      const loginData = {
        Email: this.loginWithEmailForm.controls['Email'].value,
        Password: this.loginWithEmailForm.controls['Password'].value,
      };
      this.userService.loginWithEmailId(loginData).subscribe({
        next: (res) => {
          if (res.message == 'Login Successfull') {
            this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
            this.cachaService.setIsUserLoggedIn(true);
            this.getUserDetailsById(res.userId);
            this.isUserLogged.emit(true);
            this.userid.emit(res.userId);
            this.activeModal.close();
            this.notificationService.notifyMessage(NotificationType.Success, `You're Login Successfully!`);
          }
        },
        error: (error) => {
          if (error.status === 400) {
            this.notificationService.notifyMessage(NotificationType.Success, `Invalid Email or Password`);
          }
        },
      });
    } else {
      if (this.loginWithEmailForm.invalid) {
        return;
      } else {
        this.validatePhoneNumber = true;
      }
    }
  }

  VerifyOtp() {
    this.isVerifyOtp = true;
    const countrydata = this.countryCodes.find(x =>x.code ==this.mobileVerificationForm.value.PhoneNo.split('-')[0]);
    this.country = countrydata.country;
    this.userService.checkUser(this.mobileVerificationForm.value.PhoneNo).subscribe({
      next: (isExistUser) => {
        this.isVerifyOtp = false;
        this.notificationService.notifyMessage(NotificationType.Warning, `You are already registered with this number!`);
      },
      error:(error)=>{
        if (this.isUserExist == true && this.userData[0].Mobile != null) {
          this.userID = this.userData[0].Id;
          this.setUserLoggedIn();
        } else {
          if (this.googleSignInEmail) {
            this.userService.checkEmailId(this.googleSignInEmail).subscribe({
              next: (data) => {
                if (data.length > 0) {
                  this.isUserExist = true;
                  this.userData = data;
                  this.userID = this.userData[0].Id;
                  this.storageService.setItem(
                    StorageKey.MOBILE_NO,
                    this.mobileVerificationForm.value.PhoneNo,
                    StorageType.LOCAL
                  );
                  this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
                    centered: true,
                    backdrop: 'static',
                  });
                  this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
                    this.modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
                      this.otp = otp;
                      this.modalRefOTPVerification.componentInstance.VerificationId.subscribe((VerificationId: number) => {
                        this.verificationId = VerificationId;
                        if (isOtpVerfied == true && VerificationId) {
                          if (this.isUserExist == true && this.userData[0].Email) {
                            this.getUserDetailsById(this.userID);
                            this.activeModal.close();
                            const modalRefRegistration = this.modalService.open(RegistrationComponent, {
                              centered: true,
                              backdrop: 'static',
                            });
                            
                            modalRefRegistration.componentInstance.isShowCrossbtn = true;
                            modalRefRegistration.componentInstance.password = this.userData[0].Password;
                            modalRefRegistration.componentInstance.isGoogleSignIn = true;
                            modalRefRegistration.componentInstance.googleSignInUserId = this.userID;
                            modalRefRegistration.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
                              if (isUserLogged == true) {
                                this.isUserLogged.emit(isUserLogged);
                              }
                            });
                          } else {
                            this.registerUser();
                          }
                        }
                      });
                    });
                  });
                } else {
                  this.storageService.setItem(
                    StorageKey.MOBILE_NO,
                    this.mobileVerificationForm.value.PhoneNo,
                    StorageType.LOCAL
                  );
                  this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
                    centered: true,
                    backdrop: 'static',
                  });
                  this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
                    this.modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
                      this.otp = otp;
                      this.modalRefOTPVerification.componentInstance.VerificationId.subscribe((VerificationId: number) => {
                        this.verificationId = VerificationId;
                        if (isOtpVerfied == true && VerificationId) {
                          if (this.isUserExist == true && this.userData[0].Email) {
                            this.getUserDetailsById(this.userID);
                            this.activeModal.close();
                            const modalRefRegistration = this.modalService.open(RegistrationComponent, {
                              centered: true,
                              backdrop: 'static',
                            });
                            modalRefRegistration.componentInstance.isShowCrossbtn = true;
                            modalRefRegistration.componentInstance.password = this.userData[0].Password;
                            modalRefRegistration.componentInstance.isGoogleSignIn = true;
                            modalRefRegistration.componentInstance.googleSignInUserId = this.userID;
                            modalRefRegistration.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
                              if (isUserLogged == true) {
                                this.isUserLogged.emit(isUserLogged);
                              }
                            });
                          } else {
                            this.registerUser();
                          }
                        }
                      });
                    });
                  });
                }
                // this.isUserExist = true;
                // this.userData = data;
                // this.userID = this.userData[0].Id;
                // this.storageService.setItem(
                //   StorageKey.MOBILE_NO,
                //   this.mobileVerificationForm.value.PhoneNo,
                //   StorageType.LOCAL
                // );
                // this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
                //   centered: true,
                //   backdrop: 'static',
                // });
                // this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
                //   this.modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
                //     this.otp = otp;
                //     this.modalRefOTPVerification.componentInstance.VerificationId.subscribe((VerificationId: number) => {
                //       this.verificationId = VerificationId;
                //       if (isOtpVerfied == true && VerificationId) {
                //         if (this.isUserExist == true && this.userData[0].Email) {
                //           this.getUserDetailsById(this.userID);
                //           this.activeModal.close();
                //           const modalRefRegistration = this.modalService.open(RegistrationComponent, {
                //             centered: true,
                //             backdrop: 'static',
                //           });
                //           modalRefRegistration.componentInstance.isShowCrossbtn = true;
                //           modalRefRegistration.componentInstance.password = this.userData[0].Password;
                //           modalRefRegistration.componentInstance.isGoogleSignIn = true;
                //           modalRefRegistration.componentInstance.googleSignInUserId = this.userID;
                //           modalRefRegistration.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
                //             if (isUserLogged == true) {
                //               this.isUserLogged.emit(isUserLogged);
                //             }
                //           });
                //         } else {
                //           this.registerUser();
                //         }
                //       }
                //     });
                //   });
                // });
              },
              error: (error) => {
                this.userService.checkUser(this.mobileVerificationForm.value.PhoneNo).subscribe({
                  next: (data) => {
                    this.isVerifyOtp = false;
                    this.notificationService.notifyMessage(
                      NotificationType.Warning,
                      `You're already Register with this number!`
                    );
                  },
                  error: (error) => {
                    this.storageService.setItem(
                      StorageKey.MOBILE_NO,
                      this.mobileVerificationForm.value.PhoneNo,
                      StorageType.LOCAL
                    );
                    this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
                      centered: true,
                      backdrop: 'static',
                    });
                    this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
                      this.modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
                        this.otp = otp;
                        this.modalRefOTPVerification.componentInstance.VerificationId.subscribe(
                          (VerificationId: number) => {
                            this.verificationId = VerificationId;
                            if (isOtpVerfied == true && VerificationId) {
                              if (this.isUserExist == true && this.userData[0].Email) {
                                this.updateUserDetails();
                              } else {
                                this.registerUser();
                              }
                            }
                          }
                        );
                      });
                    });
                  },
                });
              },
            });
          } else {
            this.userService.checkUser(this.mobileVerificationForm.value.PhoneNo).subscribe({
              next: (data) => {
                this.isVerifyOtp = false;
                this.notificationService.notifyMessage(
                  NotificationType.Warning,
                  `You're already Register with this number!`
                );
              },
              error: (error) => {
                this.storageService.setItem(
                  StorageKey.MOBILE_NO,
                  this.mobileVerificationForm.value.PhoneNo,
                  StorageType.LOCAL
                );
                this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
                  centered: true,
                  backdrop: 'static',
                });
                this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
                  this.modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
                    this.otp = otp;
                    this.modalRefOTPVerification.componentInstance.VerificationId.subscribe((VerificationId: number) => {
                      this.verificationId = VerificationId;
                      if (isOtpVerfied == true && VerificationId) {
                        if (this.isUserExist == true && this.userData[0].Email) {
                          this.updateUserDetails();
                        } else {
                          this.registerUser();
                        }
                      }
                    });
                  });
                });
              },
            });
          }
        }
      }
    });
   
    
  }

  updateUserDetails() {
    if (this.isGoogleSignIn == true) {
      this.sourceId = 45;
    } else {
      this.sourceId = this.sourceId;
    }
    if (this.googleSignInEmail != null || this.googleSignInEmail != '' || this.googleSignInEmail != undefined) {
      this.registrationModal = {
        mobile: this.mobileVerificationForm.value.PhoneNo,
        name: this.userData[0].Name,
        email: this.googleSignInEmail,
        sourceNameId: this.sourceId,
        userDetails: {
          id: 0,
          userId: this.userData[0].Id,
          schoolName: '',
          grade: gradeId,
          modifiedDate: this.transformDate(new Date())?.toString(),
        },
        mobileVerification: {
          verificationId: 0,
        },
      };
    } else {
      var gradeId: any = this.mobileVerificationForm.value.gradeId;
      this.registrationModal = {
        mobile: this.mobileVerificationForm.value.PhoneNo,
        name: this.mobileVerificationForm.value.name,
        email: this.mobileVerificationForm.value.email,
        sourceNameId: this.sourceId,
        userDetails: {
          id: 0,
          userId: this.userData[0].Id,
          schoolName: '',
          grade: gradeId,
          modifiedDate: this.transformDate(new Date())?.toString(),
        },
        mobileVerification: {
          verificationId: 0,
        },
      };
    }
    this.userService.updateUserDetail(this.userData[0].Id, this.registrationModal).subscribe((data) => {
      this.userID = this.userData[0].Id;
      this.setUserLoggedIn();
    });
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddTHH:mm:ss.SSS'Z'");
  }

  setUserLoggedIn() {
    this.cachaService.setIsUserLoggedIn(true);
    this.getUserDetailsById(this.userID);
    this.activeModal.close();
    this.isUserLogged.emit(this.storageService.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL));
    this.notificationService.notifyMessage(NotificationType.Success, `Logged In SuccessFully`);
    if (this.sourceId == 30) {
      this.getUserStatus();
    }
  }

  registerUser(): any {
    const countrydata = this.countryCodes.find(x =>x.code ==this.mobileVerificationForm.value.PhoneNo.split('-')[0]);
    this.country = countrydata.country;
    if(this.googleSignInEmail)
    {
      const mobileVerification = {
        otp: this.otp,
        PhoneNo: this.mobileVerificationForm.value.PhoneNo,
      };
      this.registration = {
        id: 0,
        name: '',
        email: this.googleSignInEmail,
        isActive: true,
        password: this.loginWithEmailForm.value.Password,
        userTypeId: 1,
        mobile: this.mobileVerificationForm.value.PhoneNo,
        createdDateTime: this.transformDate(new Date())?.toString(),
        modifiedBy: '',
        lastModified: this.transformDate(new Date())?.toString(),
        registrationUrl: String(window.location.pathname),
        sourceNameId: 45,
        ipCountry: this.country,
        ipAddress: this.clientIPAdress,
        city: '',
        VerificationId: this.verificationId,
        otp: this.otp,
        userDetails: {
          id: 0,
          grade: '',
          userId: 0,
          schoolName: '',
          modifiedDate: this.transformDate(new Date())?.toString(),
          country: this.country,
          city: this.city,
        },
        mobileVerification: {
          otp: this.otp,
          mobile: this.mobileVerificationForm.value.PhoneNo,
        },
      };
      
      if (this.registration.mobile != null) {
        this.modalRefOTPVerification.componentInstance.isLoading = true;
        this.userService.create(this.registration).subscribe({
          next: (data) => {
            if (data.result.value.id != null) {
              this.userID = data.result.value.id;
              this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
              this.cachaService.setIsUserLoggedIn(true);
              this.getUserDetailsById(this.userID);
              this.activeModal.close();
              const modalRefRegistration = this.modalService.open(RegistrationComponent, {
                centered: true,
                backdrop: 'static',
              });
              modalRefRegistration.componentInstance.isShowCrossbtn = true;
              modalRefRegistration.componentInstance.password = this.loginWithEmailForm.controls['Password'].value;
              modalRefRegistration.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
                if (isUserLogged == true) {
                  this.isUserLogged.emit(isUserLogged);
                }
              });
            } else {
              this.notificationService.notifyMessage(NotificationType.Success, `Sorry ! Unable to Register you!`);
            }
          },
          error: (error) => {
            this.notificationService.notifyMessage(NotificationType.Success, `Sorry! Unable to register you.`);
          },
        });
      }
    }
    else
    {
    let returnValue = '';
    const mobileVerification = {
      otp: this.otp,
      PhoneNo: this.mobileVerificationForm.value.PhoneNo,
    };
    this.registration = {
      id: 0,
      name: '',
      email: this.loginWithEmailForm.value.Email,
      isActive: true,
      password: this.loginWithEmailForm.value.Password,
      userTypeId: 1,
      mobile: this.mobileVerificationForm.value.PhoneNo,
      createdDateTime: this.transformDate(new Date())?.toString(),
      modifiedBy: '',
      lastModified: this.transformDate(new Date())?.toString(),
      registrationUrl: String(window.location.pathname),
      sourceNameId: this.sourceId,
      ipCountry: this.country,
      ipAddress: this.clientIPAdress,
      city: '',
      VerificationId: this.verificationId,
      otp: this.otp,
      userDetails: {
        id: 0,
        grade: '',
        userId: 0,
        schoolName: '',
        modifiedDate: this.transformDate(new Date())?.toString(),
        country: this.country,
        city: this.city,
      },
      mobileVerification: {
        otp: this.otp,
        mobile: this.mobileVerificationForm.value.PhoneNo,
      },
    };
    if (this.registration.mobile != null) {
      this.modalRefOTPVerification.componentInstance.isLoading = true;
      this.userService.create(this.registration).subscribe({
        next: (data) => {
          if (data.result.value.id != null) {
            this.userID = data.result.value.id;
            this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
            this.cachaService.setIsUserLoggedIn(true);
            this.getUserDetailsById(this.userID);
            this.activeModal.close();
            const modalRefRegistration = this.modalService.open(RegistrationComponent, {
              centered: true,
              backdrop: 'static',
            });
            modalRefRegistration.componentInstance.isShowCrossbtn = true;
            modalRefRegistration.componentInstance.password = this.loginWithEmailForm.controls['Password'].value;
            modalRefRegistration.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
              if (isUserLogged == true) {
                this.isUserLogged.emit(isUserLogged);
              }
            });
          } else {
            this.notificationService.notifyMessage(NotificationType.Success, `Sorry ! Unable to Register you!`);
          }
        },
        error: (error) => {
          this.notificationService.notifyMessage(NotificationType.Success, `Sorry! Unable to register you.`); 
        },
      });
    }
  }
}
  getUserStatus(): any {
    if (this.userID) {
      this.trialService.GetOrder(this.userID).subscribe((data) => {
        if (data.length > 0) {
        } else {
          setTimeout(() => {
            const modalRef = this.modalService.open(TrialUserComponent, {
              centered: true,
              size: 'xl',
              backdrop: 'static',
            });
            modalRef.componentInstance.UserId = this.userID;
            modalRef.componentInstance.GradeId = this.grade;
          }, 3000);
        }
      });
    }
  }
}
